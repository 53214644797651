const linkResolver = (doc) => {
  // URL for a blog post type
  //if (doc.type === 'blog_post') {
  //  return `/blog/${doc.uid}`
  //}

  if (doc.type === 'job_post') {
    return `/job/${doc.id}/remote-${doc.uid}`
  }

  if (doc.type === 'company') {
    return `/company/${doc.uid}`
  }

  // Backup for all other types
  return `/${doc.uid}`
}

module.exports = linkResolver