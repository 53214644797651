import React from "react"
import { Link, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import htmlSerializer from "../../../utils/htmlSerializer"
import styled from "styled-components"

import Layout from "../../../components/Layout"
import Seo from "../../../components/seo"

const StyledKeywordList = styled.span`
  &:not(:last-of-type)::after {
    content: ', ';
  }

`

const JobDetailsPageTemplate = ({ data }) => {
  const job = data.prismicJobPost
  const company = job.data.company.document
  const compLogo = getImage(company.data.logo)

  return (
    <Layout>
      <Seo
        title={job.data.job_title ? `Remote ${job.data.job_title} at ${company.data.company_name}` : "Job Title"}
      />

      <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
        <div className="flex items-center">
          <div className="rounded-full flex items-center justify-center flex-shrink-0 mr-6" style={{ width: '80px', height: '80px' }}>
            <GatsbyImage
              alt={company.data.logo.alt ? company.data.logo.alt : ""}
              image={compLogo}
              formats={["auto", "webp", "avif"]}
              className="rounded-full mx-auto"
            />
          </div>
          <div>
            <Link to={`/company/${company.uid}`}>
              <h2 className="text-gray-300 text-2xl lg:text-4xl tracking-wider font-semibold">{company.data.company_name ? company.data.company_name : "Company Name"}</h2>
            </Link>
            <p className="text-gray-300 text-sm lg:text-base">{company.data.tagline ? company.data.tagline : "Company Tagline"}</p>
          </div>
        </div>

        <div className="flex flex-col xl:flex-row items-start">
          <div className="w-full xl:w-9/12 bg-site-color-brand-3 mt-8 p-6 lg:p-12 rounded-2xl">
            <h1 className="text-gray-200 text-3xl lg:text-5xl font-black mb-12">Remote {job.data.job_title ? job.data.job_title : "Job Title"}</h1>

            <div className="grid grid-cols-1 xl:grid-cols-4 gap-0 xl:gap-6 mb-6">
              <div className="mb-10">
                <h3 className="text-gray-400 text-sm uppercase font-semibold">Remote Location</h3>
                <p className="text-gray-300 mt-1">{job.data.remote_location ? job.data.remote_location : "None"}</p>
              </div>

              <div className="mb-10 xl:ml-8">
                <h3 className="text-gray-400 text-sm uppercase font-semibold">Job Type</h3>
                <p className="text-gray-300 mt-1">{job.data.job_type ? job.data.job_type : "None"}</p>
              </div>

              {job.data.minimum_annual_salary
                ?
                <div className="mb-10">
                  <h3 className="text-gray-400 text-sm uppercase font-semibold">Compensation</h3>
                  <p className="text-gray-300 mt-1">{job.data.minimum_annual_salary} {job.data.maximum_annual_salary ? `- ${job.data.maximum_annual_salary}` : null}</p>
                </div>
                :
                null
              }

              <div className="mb-10">
                <h3 className="text-gray-400 text-sm uppercase font-semibold">Category</h3>
                <p className="text-gray-300 mt-1">{job.data.job_category ? job.data.job_category : "None"}</p>
              </div>
            </div>

            <div className="job-desc">
              {job.data.job_description
                ?
                <RichText
                  render={job.data.job_description.richText}
                  htmlSerializer={htmlSerializer}
                />
                :
                null
              }
            </div>

            {job.data.apply_link
              ?
              <div className="my-12">
                <a
                  href={job.data.apply_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="text-indigo-100 bg-indigo-600 hover:bg-indigo-700 hover:text-white px-10 py-4 rounded-md text-xl font-semibold">
                    Apply Now
                  </button>
                </a>
              </div>
              :
              null
            }

            <div className="mb-5">
              <Link
                to={`/company/${company.uid}`}
                className="text-gray-300 hover:text-gray-400 mt-1 underline"
              >
                View all jobs at {company.data.company_name}
              </Link>
            </div>

            <div>
              <Link
                to="/jobs"
                className="text-gray-300 hover:text-gray-400 mt-1 underline italic"
              >
                Back to all jobs
              </Link>
            </div>
          </div>

          <div className="w-full xl:w-3/12 mt-6 p-6 lg:p-12">
            {job.data.apply_link
              ?
              <div className="hidden xl:block mb-10">
                <a
                  href={job.data.apply_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="text-indigo-100 bg-indigo-600 hover:bg-indigo-700 hover:text-white px-6 py-2 2xl:px-10 2xl:py-4 rounded-md text-lg 2xl:text-xl font-semibold">
                    Apply Now
                  </button>
                </a>
              </div>
              :
              null
            }

            <div className="mb-10">
              <h3 className="text-gray-400 text-sm uppercase font-semibold">About</h3>
              <Link
                to={`/company/${company.uid}`}
                className="text-gray-300 hover:text-gray-400 mt-2 underline"
              >
                {company.data.company_name}
              </Link>
            </div>

            <div className="mb-10">
              <h3 className="text-gray-400 text-sm uppercase font-semibold">Skills</h3>
              <p className="text-gray-300 mt-1">
                {job.data.keywords.map(item => {
                  return (
                    <StyledKeywordList>{item.keyword}</StyledKeywordList>
                  )
                })}
              </p>
            </div>

            <div className="hidden xl:block mb-5">
              <Link
                to={`/company/${company.uid}`}
                className="text-gray-300 hover:text-gray-400 mt-1 underline"
              >
                View all jobs at {company.data.company_name}
              </Link>
            </div>

            <div className="hidden xl:block">
              <Link
                to="/jobs"
                className="text-gray-300 hover:text-gray-400 mt-1 underline italic"
              >
                Back to all jobs
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobDetailsPageTemplate

export const JobDetailsPageTemplateQuery = graphql`
  query($id: String!) {
    prismicJobPost(id: { eq: $id }) {
      id
      prismicId
      uid
      data {
        apply_email
        featured
        highlighted
        job_category
        job_title
        job_type
        job_description {
          richText
        }
        maximum_annual_salary
        minimum_annual_salary
        remote_location
        apply_link {
          url
        }
        keywords {
          keyword
        }
        company {
          document {
            ... on PrismicCompany {
              id
              uid
              data {
                company_name
                discord_url
                logo {
                  alt
                  gatsbyImageData(
                    width: 80
                  )
                }
                tagline
                telegram_url
                twitter_url
                website_url
              }
            }
          }
        }
      }
    }
  }
`
